<template>
  <div class="container py-3" id="checkout-thankyou">
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
    />
    <b-card :title="$t('booking.guide.payment') + paymentMethodName" class="mt-3 checkout--card">
      <!--    <b-card :title="'Petunjuk Pembayaran via ' + bookingOrder.jenis_transfer" class="mt-3">-->
      <div class="clearfix mb-20"></div>
      <b-tabs>
        <b-tab title="via ATM Mandiri / Bersama" active>
          <ol class="mt-3">
            <li>{{ $t('booking.guide.mandiri.msg1') }}</li>
            <li>{{ $t('booking.guide.mandiri.msg2') }}</li>
            <li>{{ $t('booking.guide.mandiri.msg3') }}</li>
            <li>
              {{ $t('booking.guide.mandiri.msg4') }}{{ virtualAccountNumber }}
              <!--              {{ $t('booking.guide.mandiri.msg4') }}{{ bookingOrder.virtual_account }}-->
              {{ $t('booking.guide.mandiri.msg4_2') }}
            </li>
            <li>{{ $t('booking.guide.mandiri.msg5') }}</li>
            <li>{{ $t('booking.guide.mandiri.msg6') }}</li>
            <li>{{ $t('booking.guide.mandiri.msg7') }}</li>
          </ol>
        </b-tab>
        <b-tab title="via ATM BCA / Prima">
          <ol class="mt-3">
            <li>{{ $t('booking.guide.BCA.msg1') }}</li>
            <li>{{ $t('booking.guide.BCA.msg2') }}</li>
            <li>{{ $t('booking.guide.BCA.msg3') }}</li>
            <li>{{ $t('booking.guide.BCA.msg4') }}</li>
            <li>{{ $t('booking.guide.BCA.msg5') }}</li>
            <li>
              {{ $t('booking.guide.BCA.msg6') }} {{ virtualAccountNumber }}
              <!--              {{ $t('booking.guide.BCA.msg6') }} {{ bookingOrder.virtual_account }}-->
              {{ $t('booking.guide.BCA.msg6_5') }}
            </li>
            <li>{{ $t('booking.guide.BCA.msg7') }}</li>
          </ol>
        </b-tab>
        <b-tab title="ATM Permata / Alto">
          <ol class="mt-3">
            <li>{{ $t('booking.guide.permata.msg1') }}</li>
            <li>{{ $t('booking.guide.permata.msg2') }}</li>
            <li>{{ $t('booking.guide.permata.msg3') }}</li>
            <li>{{ $t('booking.guide.permata.msg4') }}</li>
            <li>
              {{ $t('booking.guide.permata.msg5') }} {{ virtualAccountNumber }}
              <!--              {{ $t('booking.guide.permata.msg5') }} {{ bookingOrder.virtual_account }}-->
              {{ $t('booking.guide.permata.msg5_5') }}
            </li>
            <li>{{ $t('booking.guide.permata.msg6') }}</li>
            <li>{{ $t('booking.guide.permata.msg7') }}</li>
          </ol>
        </b-tab>
      </b-tabs>
      <div class="col-md-12 col-xs-12 col-sm-12 mt-5">
        <!--        <router-link-->
        <!--          :to="`/mybooking/detail?type=BOOKING&uuid=${bookingOrder.uuid}`"-->
        <!--          class="btn btn-primary&#45;&#45;outline btn-block"-->
        <!--          >{{ $t('thankyouPage.viewBookButton') }}-->
        <!--        </router-link>-->
        <router-link :to="viewMybookingUrl" class="btn btn-primary--outline btn-block"
          >{{ $t('general.btn.viewBook') }}
        </router-link>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const PanelDescription = () => import('@/components/booking/how-to-pay/panel-description.vue');
export default {
  name: 'permata',
  props: ['paymentMethodName', 'price', 'virtualAccountNumber', 'viewMybookingUrl', 'orderNumber'],
  components: {
    PanelDescription,
  },
  computed: {
    ...mapState({
      bookingOrder: state => state.v2.booking.bookingOrder,
    }),
  },
};
</script>

<style scoped></style>
